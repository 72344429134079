<template>
	<div class="taskCard" >
		<div class="tasks_card box-shadow-1">
			<div class="tasks_card__info-col">
                <h4 class="title">{{ task.rackId }}</h4>
                <div><i class="fas fa-thumbtack"></i>{{ task.type | uppercase }}</div>
				<div><i class="fas fa-hashtag"></i>{{ task.batch.quantity | qtyDisp }}</div>
				<div><i class="fas fa-seedling"></i>{{ task.taskDesc }}</div>
                <div><i class="fas fa-calendar"></i>{{ task.planned.date | formatDate }}</div>
                <div><i class="fas fa-user"></i>{{ task.planned.user }}</div>
			</div>
			
			<div class="tasks_card__btn-col">
                <b-button v-if="task.type=='start batch'" :variant="btnVariant" @click="goToBatch(task.rackId, task.zone)">
                    <i class="fas fa-arrow-right"></i>	
                </b-button>
                <b-button v-else-if="task.type=='end batch'" :variant="btnVariant" @click="goToHarvest(task.batch._id)">
                    <i class="fas fa-balance-scale-right"></i>
                </b-button>
			</div>
		</div>
	</div>
	
</template>

<script>
import moment from "moment";
export default {
	name: "TaskCard",
	props : ["task"],
    filters: {
		formatDate: function (value) {
			if (value) {
				return moment(String(value)).format("DD-MM-YYYY");
			}
		},
        uppercase: function (v) {
            return v.toUpperCase();
        },
		qtyDisp: function(value){
			return `${value} ${value > 1 ? 'units' : 'unit'}`;
		},
    },
	data(){
		return {
			
		}
	},
	computed: {
		btnVariant(){
			return this.task.today ? "warning" : "secondary"
		}
	},
	methods: {
		goToBatch(ga, zone){
			this.$router.push({
				name: 'crops',
				params: { farm: this.$route.params.farm },
				query: { growarea: ga, zone: zone }
			});
		},
		goToHarvest(bId){
			this.$router.push({
				name: 'harvest',
				params: { farm: this.$route.params.farm },
				query: { batid: bId }
			});
		}
	}
}
</script>

<style>
.tasks_card .title {
    margin-right: 20px;
}
</style>