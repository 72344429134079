<template>
	<div class="" v-if="dataReady1">
		<div id="filters" class="container">
			<div class="row">
				<div class="col-5">
					<h6> Growareas </h6>
					<MultiSelect 
						v-model="selectedGAs"
						:options="selGAoptions"
						:multiple="true"
						:searchable="true"
						:close-on-select="false"
						:show-labels="false"
						placeholder="Filter Growareas"
					></MultiSelect>
				</div>
				<div class="col-2">
					<h6> Zone </h6>
					<v-select
						:clearable="false"
						:searchable="false"
						:options="zoneOptions"
						v-model="selectedZone"
					/>
				</div>
				<div class="col-5">
					<h6> Task Type </h6>
					<MultiSelect 
						v-model="selectedTaskTypes"
						:options="taskTypeOptions"
						:multiple="true"
						:searchable="true"
						:close-on-select="false"
						:show-labels="false"
						placeholder="Filter Task Types"
					></MultiSelect>
				</div>
			</div>
		</div>

		<div id="tasks" v-if="dataReady2">
			<div class="accordion" role="tablist">
				<div class="mb-1">
					<b-button block v-b-toggle.accordion-tasks variant="warning"> {{ tasks.length }} Tasks</b-button>
					<b-collapse id="accordion-tasks" accordion="my-accordion" role="tabpanel" visible>
						<div v-if="tasks.length > 0" class="tasks_list">
							<TaskCard v-for="(task, tkey) in tasks" :key="tkey" :task="task" :class="{'today': task.today}"></TaskCard>
						</div>
						<h6 v-else> No tasks available </h6>
					</b-collapse>
				</div>
			</div>
			<div class="accordion" role="tablist">
				<div class="mb-1">
					<b-button block v-b-toggle.accordion-delayed variant="danger"> {{ nDelayedTasks }} Delayed</b-button>
					<b-collapse id="accordion-delayed" accordion="my-accordion" role="tabpanel">
						<div v-if="delayed_tasks.length > 0">
							<TaskCard v-for="(delay, dkey) in delayed_tasks" :key="dkey" :task="delay"></TaskCard>
						</div>
						<h6 v-else> No tasks available </h6>
					</b-collapse>
				</div>
			</div>
			<div class="accordion" role="tablist">
				<div class="mb-1">
					<b-button block v-b-toggle.accordion-completed variant="success"> {{ nCompletedTasks }} Completed </b-button>
					<b-collapse id="accordion-completed" accordion="my-accordion" role="tabpanel">
						<div v-if="completed_tasks.length > 0">
							<TaskCard v-for="(complete, ckey) in completed_tasks" :key="ckey" :task="complete"></TaskCard>
						</div>
						<h6 v-else> No tasks available </h6>
					</b-collapse>
				</div>
			</div>
		</div>
	</div>
	<div v-else> Loading.. </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import qs from "qs";
import TaskCard from "../components/tasks/TaskCard.vue";

export default {
	name: "Tasks",
	components : {
		TaskCard
	},
	props : ["farm", "zone"],
	filters: {
		formatDate: function (value) {
			if (value) {
				return moment(String(value)).format("DD-MM-YYYY");
			}
		}
	},
	computed:{
		nTasks() {
			let sGAs = this.selectedGAs;
			return sGAs.length > 0 ? this.tasks.filter((t) => sGAs.includes(t.rackId)).length : this.tasks.length;
		},
		nDelayedTasks() {
			let sGAs = this.selectedGAs;
			return sGAs.length > 0 ? this.delayed_tasks.filter((t) => sGAs.includes(t.rackId)).length : this.delayed_tasks.length;
		},
		nCompletedTasks() {
			let sGAs = this.selectedGAs;
			return sGAs.length > 0 ? this.completed_tasks.filter((t) => sGAs.includes(t.rackId)).length : this.completed_tasks.length;
		},
	},
	data(){
		return {
			dataReady1: false,
			dataReady2: false,
			zoneOptions : [], // zones in current farm
			selGAoptions: [],
			GAoptions: [],
			selectedGAs: [],
			taskTypeOptions: ["start batch", "end batch"],
			selectedZone : this.zone, // current selected zone
			selectedFarm: this.farm,
			selectedTaskTypes: ["start batch", "end batch"],
			tasks: [],
			delayed_tasks: [],
			completed_tasks: []			
		}
	},
	mounted(){
		this.getGrowAreas(false)
	},
	watch: {
		farm : function(newVal){
			this.selectedFarm = newVal;
			this.selectedZone = null;
			this.dataReady1 = false;
			this.dataReady2 = false;
			this.getGrowAreas(false);
		},
		zone: function(){
			this.dataReady1 = false;
			this.dataReady2 = false;
			this.getGrowAreas(false);
		},
		selectedZone: function(){
			this.dataReady2 = false;
			this.selGAoptions = [];
			if(this.selectedZone == "All"){
				for(var z in this.GAoptions){
					this.selGAoptions = this.selGAoptions.concat(this.GAoptions[z]);
				}
			}
			else {
				this.selGAoptions = this.GAoptions[this.selectedZone];
			}
			this.getTasks();
		},
		selectedTaskTypes: function(){
			this.dataReady2 = false;
			this.getTasks();
		},
		selectedGAs: function(){
			this.dataReady2 = false;
			this.getTasks();
		}
	},
	methods : {
		getGrowAreas(rfsh){
			axios
			.get(`/${this.farm}/growareas`, { })
			.then((response) => {					
				this.growareas = response.data;
				if(!rfsh){
					this.GAoptions = [];
					for(var zone in this.growareas){
						this.GAoptions[zone] = [];
						for(var ga in this.growareas[zone]){
							this.GAoptions[zone].push(this.growareas[zone][ga].growarea);
						}
					}
					this.zoneOptions = [ "All" , ...Object.keys(response.data)];
					this.selectedZone = this.zoneOptions[0];
					this.dataReady1 = true;
				}
				this.getTasks();
			});
		},
		getTasks(){
			axios.get(`/${this.selectedFarm}/tasks`, { params: {
				zone: this.selectedZone != "All" ? this.selectedZone : null,
				growareas: qs.stringify({data: this.selectedGAs}),
				taskTypes: qs.stringify({data: this.selectedTaskTypes})
			}})
			.then((response) => {
				// Remove tasks where batch is inactive
				let rawtasks = response.data.filter((t) => {
					return t.batch?.completed_end == undefined;
				});
				// Filter today's and upcoming tasks
				this.tasks = rawtasks.filter((t) => {
					let planned_date = moment(t.planned.date);
					t.today = planned_date.isSame(moment(), 'day') ? true : false;
					return t.completed == null && planned_date >= moment().startOf('day');
				});
				// Filter delayed tasks, active tasks that it's due date has passed
				this.delayed_tasks = rawtasks.filter((t) => {
					let planned_date = moment(t.planned.date);
					return t.completed == null && planned_date < moment().startOf('day');
				});
				// Filter tasks that have been marked as complete
				this.completed_tasks = rawtasks.filter((t) => t.completed != null);
				this.dataReady2 = true;
			})
			.catch((e) => {
				console.log("error get tasks", e)
			});
		}		
	},
}
</script>

<style>
#filters {
  background: rgba(240, 255, 240, 0.5);
}

#tasks {
	margin-top: 10px;
}

.taskCard.today {
	background-color: lightgoldenrodyellow;
}

.tasks_list {
	max-height: 90vh;
	overflow: auto;
	padding: 10px 20px;
	margin: 10px 0;
}

.tasks_card {
	padding: 10px;
	margin: 20px 0;
	text-align: left;
	border-radius: 10px;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;	
}

.tasks_card__info-col {
	display: flex;
	flex-direction: row;
	justify-content: left;
	
	text-overflow: ellipsis;
	white-space: nowrap;
	-moz-white-space: nowrap;
	overflow: hidden;
}

.tasks_card__info-col * {
	margin: 5px 5px;
}

.tasks_card__btn-col {
	display: flex;
	flex-direction: row;
	justify-content: right;
	align-items: center;
	padding-right: 0;
}

.tasks_card__btn-col * {
	margin: 3px 3px;
}
</style>